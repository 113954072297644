export const GENESIS_API_BASE_URL = "https://api.genflooring.com";
export const SUPPORT_EMAIL = "kkoh@genesisfloor.net";
export const GENESIS_LOGO_COLOR = "#002466";
export const GENESIS_LOGO_SECONDARY_COLOR = "#001843";
export const SM_HEADER_HEIGHT_INT = 64;
export const LG_HEADER_HEIGHT_INT = 80;
export const SM_HEADER_HEIGHT = SM_HEADER_HEIGHT_INT + "px";
export const LG_HEADER_HEIGHT = LG_HEADER_HEIGHT_INT + "px";
export const WINDOW_TRANSITION_WIDTH_HEADER_INT = 600;
export const LG_DRAWER_WIDTH = "160px";
export const SM_DRAWER_WIDTH = "52px";
export const MUI_LICENSE_KEY =
  "46cea116aa358a8dadafcaef16fbf9e9Tz04OTIwMCxFPTE3NDU3MjI0OTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";
export const SUPPORTED_COMPRESSED_FILE_TYPES = ["zip"];
export const SUPPORTED_DOC_FILE_TYPES = [
  "xls",
  "xlsm",
  "xlsx",
  "ppt",
  "pptx",
  "doc",
  "docx",
  "pdf",
  "txt",
  "csv",
];
export const SUPPORTED_IMG_FILE_TYPES = ["jpg", "jpeg", "png", "gif"];
export const SUPPORTED_PREVIEW_FILE_TYPES = SUPPORTED_DOC_FILE_TYPES.concat(
  SUPPORTED_IMG_FILE_TYPES,
);
export const SUPPORTED_UPLOAD_FILE_TYPES = SUPPORTED_DOC_FILE_TYPES.concat(
  SUPPORTED_IMG_FILE_TYPES,
)
  .concat(SUPPORTED_COMPRESSED_FILE_TYPES)
  .concat(["heic"])
  .map((type) => "." + type)
  .join(",");
export const PROJECT_STATUSES = [
  "Archive",
  "Proposal Generation",
  "Proposal Completed",
  "Proposal Sent",
  "PO Received",
  "Schedule Install & Order Materials",
  "Awaiting Installation",
  "Invoice",
  "Awaiting Payment",
  "Job Complete",
];
